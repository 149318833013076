import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Icon from "../../components/custom-widgets/icon";
import List from "../../components/custom-widgets/list";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import Tab from "../../components/tab/custom-tab";

// Helper Functions
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const OpenBusinessBankAccountOnline = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/open-business-bank-account-online/hero-open-business-account-01-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "open-business-bank-account-online-hero",
    ...getHeroImgVariables(imgData),
    chevronBgClass: "bg-info",
    altText: "A small business owner using a tablet in the workplace",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Easily Open a New WaFd Bank Business Account Online",
            class: "text-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking/small-business",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Open a Business Bank Account Online"
    }
  ];
  const title = "Open a Business Bank Account Online",
    description =
      "Open a business bank account online today at WaFd Bank. Choose from our business accounts including checking, savings, high interest, and money market accounts.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/open-business-bank-account-online"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-open-business-account-01-250.jpg"
      }
    ]
  };

  const simpleCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: "simple-business-list-item-1",
        text: "Only $100 to open"
      },
      {
        id: "simple-business-list-item-2",
        text: "Pay bills online"
      },
      {
        id: "simple-business-list-item-3",
        text: "Mobile Photo Deposit & Remote Deposit Capture"
      },
      {
        id: "simple-business-list-item-4",
        text: "eStatements"
      },
      {
        id: "simple-business-list-item-5",
        text: "Automatic Sweep Accounts"
      },
      {
        id: "simple-business-list-item-6",
        text: "eWire Transfer Service"
      },
      {
        id: "simple-business-list-item-7",
        text: "Electronic ACH Payments"
      }
    ]
  };

  const interestCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: "interest-checking-list-item-1",
        text: "Only $100 to open"
      },
      {
        id: "interest-checking-list-item-2",
        text: "Pay bills online"
      },
      {
        id: "interest-checking-list-item-3",
        text: "Earn interest with a balance above $1000"
      },
      {
        id: "interest-checking-list-item-4",
        text: "Mobile Photo Deposit & Remote Deposit Capture"
      },
      {
        id: "interest-checking-list-item-5",
        text: "eStatements"
      },
      {
        id: "interest-checking-list-item-6",
        text: "Automatic Sweep Accounts"
      },
      {
        id: "interest-checking-list-item-7",
        text: "eWire Transfer Service"
      },
      {
        id: "interest-checking-list-item-8",
        text: "Electronic ACH Payments"
      },
      {
        id: "interest-checking-list-item-9",
        text: "150 checks and 90 deposit per month"
      }
    ]
  };

  const smallBusinessSavingsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: "business-savings-list-item-1",
        text: "Only $25 initial deposit"
      },
      {
        id: "business-savings-list-item-2",
        text: "No monthly fee*"
      },
      {
        id: "business-savings-list-item-3",
        text: "Earn interest with a balance above $100"
      }
    ]
  };

  const highYeildMoneyMarketListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: "business-money-market-list-item-1",
        text: "Only $100 initial deposit"
      },
      {
        id: "business-money-market-list-item-2",
        text: "No monthly fee"
      },
      {
        id: "business-money-market-list-item-3",
        text: "Earn interest with a balance above $1,000"
      }
    ]
  };

  const SimpleBusinessCheckingData = () => (
    <>
      <div>
        <StaticImage
          className="border-radius-bottom-0"
          src="../../images/small-business-checking.jpg"
          alt="Businesswoman holding a pen and smiling."
          placeholder="blurred"
          loading="eager"
          quality="100"
        />
      </div>
      <div className="p-3 mb-4 mb-sm-0 d-flex flex-column h-100">
        <h2>*Free Business Checking</h2>
        <h4 className="text-success font-weight-bold">Simple and direct, perfect for managing a small business.</h4>
        <p className="font-weight-bold">Everything you want in a business checking account:</p>
        <List {...simpleCheckingListData} accountName={"simple-business-checking"} />
        <p className="font-weight-bold mt-auto">
          <Link
            to="/business-banking/business-checking-account/free-business-checking"
            id="learn-more-simple-business-checking-link"
            className="text-decoration-none"
          >
            Learn more about Free Business Checking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <div>
          <a
            target="_blank"
            className="btn btn-primary mb-3"
            rel="noopener noreferrer"
            href="https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
            id="simple-business-open-account-cta"
          >
            Open an Account
          </a>
        </div>
        <p id="simple-checking-disclaimer" className="text-sm text-muted">
          *Nonsufficient Funds Charge may apply
        </p>
      </div>
    </>
  );

  const BusinessInterestCheckingData = () => (
    <>
      <div>
        <StaticImage
          className="border-radius-bottom-0"
          src="../../images/business-interest-checking.jpg"
          alt="Young Asian businessman using a smartphone while walking"
          placeholder="blurred"
          loading="eager"
          quality="100"
        />
      </div>
      <div className="p-3 mb-4 mb-sm-0 d-flex flex-column h-100">
        <h2>Business Interest Checking</h2>
        <h4 className="text-success font-weight-bold">
          This interest-bearing account is the right fit for clients with moderate activity and balances.
        </h4>
        <p className="font-weight-bold">See how WaFd Bank is here for you:</p>
        <List {...interestCheckingListData} accountName={"business-interest-checking"} />
        <p className="font-weight-bold mt-auto">
          <Link
            to="/business-banking/business-checking-account/small-business"
            id="learn-more-business-interest-checking-link"
            className="text-decoration-none"
          >
            Learn more about Business Interest Checking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <div>
          <a
            target="_blank"
            className="btn btn-primary mb-3"
            rel="noopener noreferrer"
            href="https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
            id="business-interest-checking-open-account-cta"
          >
            Open an Account
          </a>
        </div>
        <p id="interest-checking-disclaimer" className="text-sm text-muted">
          *$15 per month if balance falls below $5,000
        </p>
      </div>
    </>
  );

  const SmallBusinessSavingsData = () => (
    <>
      <div>
        <StaticImage
          className="border-radius-bottom-0"
          src="../../images/small-business-savings.jpg"
          alt="Young woman looking into a laptop and responding to an email."
          placeholder="blurred"
          loading="eager"
          quality="100"
        />
      </div>
      <div className="p-3 mb-4 mb-sm-0 d-flex flex-column h-100">
        <h2>Small Business Savings</h2>
        <h4 className="text-success font-weight-bold">
          Grow your business capital by opening a small business savings account, a simple way to earn interest on your
          extra cash.
        </h4>
        <p className="font-weight-bold">Everything you need in a Small Business Savings account:</p>
        <List {...smallBusinessSavingsListData} accountName={"small-business-savings"} />
        <p className="font-weight-bold mt-auto">
          <Link
            to="/business-banking/business-savings-account"
            id="learn-more-small-business-savings-link"
            className="text-decoration-none"
          >
            Learn more about Small Business Savings
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <div>
          <a
            target="_blank"
            className="btn btn-primary mb-3"
            rel="noopener noreferrer"
            href="https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
            id="small-business-savings-open-account-cta"
          >
            Open an Account
          </a>
        </div>
        <p id="small-business-savings-disclaimer" className="text-sm text-muted">
          *$5 per month if balance falls below $200
        </p>
      </div>
    </>
  );

  const HighYieldMoneyMarketData = () => (
    <>
      <div>
        <StaticImage
          className="border-radius-bottom-0"
          src="../../images/thumbnail-business-money-market-102224.jpg"
          alt="Two business people looking into a tablet and discussing something."
          placeholder="blurred"
          loading="eager"
          quality="100"
        />
      </div>
      <div className="p-3 mb-4 mb-sm-0 d-flex flex-column h-100">
        <h2>Business Money Market</h2>
        <h4 className="text-success font-weight-bold">Earn a Higher Rate and Keep Funds Accessible.</h4>
        <p className="font-weight-bold">Flexibility to manage your money:</p>
        <List {...highYeildMoneyMarketListData} accountName={"high-yield-mm"} />
        <p className="font-weight-bold mt-auto">
          <Link
            to="/business-banking/business-savings-account/money-market-account"
            id="learn-more-money-market-link"
            className="text-decoration-none"
          >
            Learn more about Business Money Market Accounts
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <div>
          <a
            target="_blank"
            className="btn btn-primary mb-3"
            rel="noopener noreferrer"
            href="https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
            id="high-yield-money-market-open-account-cta"
          >
            Open an Account
          </a>
        </div>
        <p id="business-money-market-disclaimer" className="text-sm text-muted">
          *$12 per month if balance falls below $1,000
        </p>
      </div>
    </>
  );

  const SavingsTabData = () => (
    <>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col-sm-6 card border-0 mb-5" id="savings-data-column">
          <div className="bg-light h-100 border-radius-12 d-flex flex-column">
            <SmallBusinessSavingsData />
          </div>
        </div>
        <div className="col-sm-6 card border-0 mb-5" id="high-yeild-money-market-data-column">
          <div className="bg-light h-100 border-radius-12 d-flex flex-column">
            <HighYieldMoneyMarketData />
          </div>
        </div>
      </div>
      <div className="text-center">
        <Link
          id="more-business-savings-accounts-cta"
          className="btn btn-link"
          to="/business-banking/business-savings-account"
        >
          More Business Savings Accounts Options
        </Link>
      </div>
    </>
  );

  const CheckingsTabData = () => (
    <>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col-sm-6 card border-0 mb-5" id="free-checking-data-column">
          <div className="bg-light h-100 border-radius-12 d-flex flex-column">
            <SimpleBusinessCheckingData />
          </div>
        </div>
        <div className="col-sm-6 card border-0 mb-5" id="stellar-checking-data-column">
          <div className="bg-light h-100 border-radius-12 d-flex flex-column">
            <BusinessInterestCheckingData />
          </div>
        </div>
      </div>
      <div className="text-center">
        <Link
          id="more-business-checking-accounts-cta"
          className="btn btn-link"
          to="/business-banking/business-checking-account"
        >
          More Business Checking Account Options
        </Link>
      </div>
    </>
  );

  const quickLinksData = [
    {
      name: "Business Credit Cards",
      text: "Flexibility and convenience, plus cash back rewards!",
      imageSource: "../../images/icons/credit-cards-icon-96.svg",
      altText: "Business Credit Cards Link",
      url: "/business-banking/small-business-credit-card"
    },
    {
      name: "Business Services",
      text: "Payment and point of sale solutions to help you run your business.",
      imageSource: "../../images/icons/merchant-services.svg",
      altText: "Business Services Link",
      url: "/business-banking/business-services"
    },
    {
      name: "Treasury Management",
      text: "Access your business accounts online with our Treasury Products.",
      imageSource: "../../images/icons/icon-treasury-management.svg",
      altText: "Treasury Management Link",
      url: "/business-banking/online-banking"
    },
    {
      name: "Commercial Banking",
      text: "Our Commercial Bankers have the expertise to serve as trusted partners for businesses in a variety of industries.",
      imageSource: "../../images/icons/icon-commercial-banking.svg",
      altText: "Commercial Banking Link.",
      url: "/commercial-banking"
    }
  ];

  const tabComponentData = {
    tabs: [
      { name: "Business Checking Accounts", data: <CheckingsTabData /> },
      { name: "Business Savings Accounts", data: <SavingsTabData /> }
    ],
    defaultActiveTabIndex: 0
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Open a Business Bank Account Online</h1>
        <div className="row">
          <div className="col-md-6">
            <h4>
              Whether you're a new entrepreneur or seasoned business owner, opening a business bank account is an
              important step in your business's money management strategy.
            </h4>
            <h4 className="font-weight-semi-bold">
              We also offer <Link to="/personal-banking">Personal</Link> and{" "}
              <Link to="/commercial-banking">Commercial</Link> accounts.
            </h4>
          </div>
          <div className="col-md-6">
            <h5>Here's what you'll need</h5>
            <ul>
              <li>Live in Arizona, California, Idaho, Nevada, New Mexico, Oregon, Texas, Utah, or Washington.</li>
              <li>Funding method, such as a credit or debit card, or routing and account number.</li>
            </ul>
          </div>
        </div>
      </section>
      <Tab {...tabComponentData} />
      <section className="bg-light" id="quick-links-section">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
            {quickLinksData.map((block, index) => {
              return (
                <div
                  key={index}
                  id={block.name.toLocaleLowerCase().replace(/ +/g, "-")}
                  className="text-center mb-3 px-2"
                >
                  <img src={block.imageSource} alt={block.altText} className="mb-3" style={{ width: "96px" }} />
                  <h3>{block.name}</h3>
                  <Link
                    to={block.url}
                    id={block.name.toLocaleLowerCase().replace(/ +/g, "-") + "-link"}
                    className="text-center mb-3 mb-lg-0 text-black text-decoration-none"
                  >
                    <span dangerouslySetInnerHTML={{ __html: block.text }} />
                    <Icon name="arrow-right" class="ml-1 text-primary" />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default OpenBusinessBankAccountOnline;
